<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="170px" :inline="true">
                <el-form-item label="电池类型：" prop="batteryType">
                    <el-select v-model="dataForm.batteryType">
                        <el-option v-for="item in batteryTypeOptions" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import global from '@/components/Global';
import message from '@/utils/message';
import mqttService from '@/api/mqtt-service';
import {queryEsaioManagerJsonSchemaDetail} from '@/api/jsonschema/jsonSchemaApi';
import jsonSchema from '@/api/jsonschema/json-schema';

export default {

    name: "battery-type",
    data() {

        return {

            deviceSn: '',
            dataForm: {

                batteryType: ''
            },
            command: {

                commandCodeR: 'get_rate_info',
                commandCodeW: 'set_battery_type',
            },
            batteryTypeOptions: [
                {
                    name : 'AGM',
                    value: '00'
                },
                {
                    name : 'Flooded',
                    value: '01'
                },
                {
                    name : 'Lib',
                    value: '02'
                },
                {
                    name : 'User-define',
                    value: '03'
                }
            ],
            jsonSchemaData: {}
        }
    },
    methods: {
        /**
         * 查询初始化.
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('电池类型读取中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
            // 查询jsonSchema
            this.queryWriteCommandJsonSchema();
        },
        /**
         * 设置提交.
         */
        submit() {

            let check = jsonSchema.validate(this.jsonSchemaData, this.dataForm);
            if (!check.pass) {

                message.error('参数错误');
                return;
            }
            let that = this;
            this.cmdLoading = loading.loading('设置电池类型指令下发中...', 30);
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeW,
                    data: that.dataForm
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        message.success('设置电池类型成功');
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },
        /**
         * 查询写指令的的json schema校验信息
         */
        queryWriteCommandJsonSchema() {

            let param = {

                deviceSn: this.deviceSn,
                commandCode: this.command.commandCodeW
            };
            queryEsaioManagerJsonSchemaDetail(param).then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.jsonSchemaData = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        /**
         * 查询指令回调.
         */
        initSuccessCallBack(data) {

            this.dataForm.batteryType = '0' + data.batteryType;
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%
}

.btn-box {

    text-align: center;
}
</style>